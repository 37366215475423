define("webapp/initializers/polyfills", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {}

  exports.default = {
    initialize: initialize
  };
});